import Vue from "vue";
import Vuex from "vuex";
import notification from "./modules/notification";
import bulksms from "./modules/bulksms";
import user from './modules/user';
import {
  SET_IMPERSONATE,
  SET_CURRENCY_RATE,
  SET_PROVIDERS_PAYMENT,
  SET_REGIONS
} from "./mutations-types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    operators: [],
    currencyRate: {},
    impersonate: false,
    regions: []
  },
  getters: {
    operators: (state) => {
      return state.operators;
    },
    impersonate: (state) => {
      return state.impersonate;
    },
    currencyRate: (state) => {
      return state.currencyRate;
    },
    regions: (state) => {
      return state.regions;
    }
  },
  mutations: {
    [SET_IMPERSONATE]: (state, value) => {
      state.impersonate = value;
    },
    [SET_CURRENCY_RATE]: (state, value) => {
      state.currencyRate = value;
    },
    [SET_PROVIDERS_PAYMENT]: (state, value) => {
      state.operators = value;
    },
    [SET_REGIONS]: (state, value) => {
      state.regions = value
    }
  },
  actions: {
    async request(store, params) {
      return Vue.axios({
        ...params,
      });
    },
    setImpersonate(store, value) {
      store.commit(SET_IMPERSONATE, value);
    },
    async setCurrencyRate(store) {
      if (store.getters.currencyRate.currency) return;
      const response = await store.dispatch("request", {
        url: "/api/settings/currency-rate",
      });
      store.commit(SET_CURRENCY_RATE, response.data);
    },
    async setPaymentProviders(store) {
      if (store.getters.operators.length) return;
      const response = await store.dispatch("request", {
        url: "/api/settings/payment-providers",
      });
      const data = response.data;
      const providers = [];
      for (const provider of data) {
        if (provider.active) {
          providers.push({...provider, avatar: `/img/${provider.avatar}` });
        }
      }
      store.commit(SET_PROVIDERS_PAYMENT, providers);
      return;
    },
    async setRegions(store) {
      if (store.getters.regions.length) return;
      const response = await store.dispatch("request", {
        url: "/api/settings/cameroon-regions",
      });
      const regions = {};
      const data = response.data;

      for(const locale in data) {
        regions[locale] = [];
        for(const code in data[locale] ) {
          regions[locale].push({ text: data[locale][code], value: code });
        }
      }

      store.commit(SET_REGIONS, regions);
      return data;
    },
  },
  modules: {
    notification: notification,
    bulksms: bulksms,
    user: user
  },
});
