import VueJs from "vue";
const Bulksms = {};

const defaultFont = "Open Sans";

const smsStatus = function() {
  return {
    total: 0,
    delivered: 0,
    undelivered: 0
  };
};

const smsProvidersStatus = function() {
  const status = smsStatus();
  return {
    mtn: status,
    ocm: status,
    nex: status,
    ctl: status,
    other: status
  };
};

Bulksms.install = function(Vue) {
  Bulksms.setDataTelcoCount = function(source = {}, key) {
    const output = {
      total: 0,
      delivered: 0,
      undelivered: 0
    };

    output.total = Vue._.has(source, key) ? source[key] : 0;
    output.delivered = Vue._.has(source, `delivered.${key}`)
      ? source["delivered"][key]
      : 0;
    output.undelivered = output.total - output.delivered;

    return output;
  };

  Bulksms.parseSummaryMessageStats = function(messages) {
    const response = {
      delivered: 0,
      pending: 0,
      total: 0,
      undelivered: 0,
      acked: 0,
      details: {}
    };

    Vue._.each(messages, message => {
      const count = parseInt(message.count);
      if (message.tracking_status) {
        if (message.tracking_status === "DELIVERED") {
          response.delivered += count;
        } else if (message.tracking_status === "PENDING") {
          response.pending += count;
        } else {
          if (
            !Object.prototype.hasOwnProperty.call(
              response.details,
              message.tracking_status
            )
          )
            response.details[message.tracking_status] = 0;
          response.details[message.tracking_status] += count;
        }

        response.acked += count;
      } else {
        response.pending += count;
      }
      if (message.tracking_status !== "DELIVERED") {
        response.undelivered += count;
      }
      response.total += count;
    });
    return response;
  };

  Bulksms.parseDetailsStats = function(sources) {
    const output = {
      days: {},
      telcos: smsProvidersStatus(),
      total: smsStatus(),
      sources: {}
    };

    Vue._.each(sources, source => {
      if (!Vue._.has(output.days, source.date)) {
        output.days[source.date] = {
          total: smsStatus(),
          telcos: smsProvidersStatus(),
          sources: {}
        };
      }
      // set telco count
      Vue._.each(output.days[source.date].telcos, (val, k) => {
        const count = Bulksms.setDataTelcoCount(source.total, k);

        output.days[source.date].telcos[k] = count;
        // set total day count
        output.days[source.date].total = Bulksms.setTotalCount(
          { ...output.days[source.date].total },
          { ...count }
        );
        // set total telcos count
        output.telcos[k] = Bulksms.setTotalCount(
          { ...output.telcos[k] },
          { ...count }
        );
        // set total count
        output.total = Bulksms.setTotalCount({ ...output.total }, { ...count });
      });
      // set sources count
      output.days[source.date].sources = source.total.source;
      // set total source;
      output.sources = Bulksms.setTotalSourceCount(
        { ...output.sources },
        { ...source.total.source }
      );
    });

    return output;
  }

  Bulksms.parseDetailsStatsByAllDay = function(sources, arrayOfDates) {
    const output = {};
    Vue._.each(arrayOfDates, day => {
      if (!Vue._.has(sources, day)) {
        output[day] = {
          sources: {},
          telcos: smsProvidersStatus(),
          total: smsStatus()
        };
      } else {
        output[day] = sources[day];
      }
    });
    return output;
  };

  Bulksms.getArrayOfDates = function(date) {
    const output = [];
    const startOfMonth = Vue.moment(date).startOf("month");
    const endOfMonth = Vue.moment(date).endOf("month");
    const today = Vue.moment();

    for (
      let m = startOfMonth;
      (m.diff(endOfMonth, "days") < 0 || m.isSame(endOfMonth, "day")) &&
      today.diff(m, "days") > 0;
      m.add(1, "days")
    ) {
      output.push(m.format("YYYY-MM-DD"));
    }

    return output;
  };

  Bulksms.setDataTelcoCount = function(source = {}, key) {
    const output = {
      total: 0,
      delivered: 0,
      undelivered: 0
    };

    output.total = Vue._.has(source, key) ? source[key] : 0;
    output.delivered = Vue._.has(source, `delivered.${key}`)
      ? source["delivered"][key]
      : 0;
    output.undelivered = output.total - output.delivered;

    return output;
  };

  Bulksms.setTotalCount = function(input, source) {
    input.total += parseInt(source.total);
    input.delivered += parseInt(source.delivered);
    input.undelivered += parseInt(source.undelivered);
    return input;
  };

  Bulksms.setTotalSourceCount = function(input, source) {
    Vue._.each(source, (val, k) => {
      if (!Vue._.has(input, k)) {
        input[k] = parseInt(val);
      } else {
        input[k] += parseInt(val);
      }
    });
    return input;
  };

  Bulksms.parseChartByStatus = function(sources, $translation) {
    const colorsMap = {
      delivered: "#00897B",
      undelivered: "#FF6F00"
    };

    const response = {
      data: [],
      options: {
        pieHole: 0.4,
        colors: [],
        fontSize: 13,
        fontName: defaultFont,
        legend: {
          textStyle: {
            fontName: defaultFont
          }
        },
        titleTextStyle: {
          fontName: defaultFont
        },
        chartArea: {
          left: "3%",
          top: "3%",
          height: "95%",
          width: "95%"
        }
      }
    };
    
    Vue._.each(sources, (value, k) => {
      if (!["delivered", "undelivered"].includes(k)) return;

      const title = $translation(`bulksms.statistic.fields.${k}.title`);
      // values
      response.data.push([title, value]);
      // colors options
      response.options.colors.push(colorsMap[k]);
    });

    // add header
    if (response.data.length > 0) {
      response.data.unshift([
        $translation("bulksms.statistic.fields.status.title"),
        $translation("bulksms.statistic.fields.sms.title")
      ]);
    }

    return response;
  }

  Bulksms.parseChartByTelco = function(sources, $translation) {
    const response = {
      data: [],
      options: {
        pieHole: 0.4,
        fontSize: 13,
        fontName: defaultFont,
        legend: {
          textStyle: {
            fontName: defaultFont
          }
        },
        tooltip: {
          textStyle: {
            fontName: defaultFont
          }
        },
        titleTextStyle: {
          fontName: defaultFont
        },
        chartArea: {
          left: "3%",
          top: "3%",
          height: "95%",
          width: "95%"
        }
      }
    };

    Vue._.each(sources, (count, telco) => {
      // values
      response.data.push([
        $translation(`bulksms.campaign.fields.operator.${telco}`),
        count.total
      ]);
    });

    // add header
    if (response.data.length > 0) {
      response.data.unshift([
        $translation("bulksms.statistic.fields.telco.title"),
        $translation("bulksms.statistic.fields.sent.title")
      ]);
    }

    return response;
  }

  Bulksms.parseChartByStatusAndDays = function(sources, $translation) {
    const response = {
      data: [],
      options: {
        colors: ["#00897B", "#FF6F00", "#1A237E"],
        fontSize: 12,
        fontName: defaultFont,
        chartArea: {
          top: "10%",
          left: "15%",
          height: "55%",
          width: "85%"
        },
        height: 300,
        legend: {
          textStyle: {
            fontName: defaultFont,
            fontSize: 12
          },
          position: "bottom"
        },
        tooltip: {
          textStyle: {
            fontName: defaultFont
          }
        },
        titleTextStyle: {
          fontName: defaultFont
        },
        vAxis: {
          textStyle: {
            fontName: defaultFont,
            fontSize: 12
          },
          titleTextStyle: {
            fontName: defaultFont,
            fontSize: 12
          },
          title: $translation("bulksms.statistic.fields.sms_amount.title")
        },
        hAxis: {
          textStyle: {
            fontName: defaultFont,
            fontSize: 12
          },
          titleTextStyle: {
            fontName: defaultFont,
            fontSize: 12
          },
          title: $translation("bulksms.statistic.fields.date.title")
        }
      }
    };

    Vue._.each(sources, (source, date) => {
      response.data.push([
        date,
        source.total.delivered,
        source.total.undelivered
      ]);
    });

    // add header
    if (response.data.length > 0) {
      response.data.unshift([
        $translation("bulksms.statistic.fields.date.title"),
        $translation("bulksms.statistic.fields.delivered.short_title"),
        $translation("bulksms.statistic.fields.undelivered.short_title")
      ]);
    }

    return response;
  };

  Bulksms.exportStatisticByDay = async function(
    date,
    sources,
    total,
    $translation
  ) {
    const workbook = new Vue.$exceljs.Workbook({
      creator: "BulkSMS",
      lastModifiedBy: "BulkSMS",
      created: new Date(),
      modified: new Date()
    });

    const worksheet = workbook.addWorksheet(
      $translation("bulksms.statistic.details.days.title2", date)
    );

    worksheet.columns = [
      {
        header: $translation("bulksms.statistic.fields.date.title"),
        key: "date",
        style: { numFmt: "yyyy-mm-dd", font: { name: "Calibri", size: 10 } },
        width: 18
      },
      {
        header: $translation("bulksms.statistic.fields.sent.title"),
        key: "total",
        width: 18,
        style: { font: { name: "Calibri", size: 10 } }
      },
      {
        header: $translation("bulksms.statistic.fields.delivered.title"),
        key: "delivered",
        width: 18,
        style: { font: { name: "Calibri", size: 10 } }
      },
      {
        header: $translation("bulksms.statistic.fields.undelivered.title"),
        key: "undelivered",
        width: 18,
        style: { font: { name: "Calibri", size: 10 } }
      }
    ];

    // set font style on the header
    worksheet.getRow(1).font = { bold: true };

    // add data
    Vue._.each(sources, (source, k) => {
      worksheet.addRow({ date: k, ...source.total });
    });

    const totalValues = [total.total, total.delivered, total.undelivered];
    worksheet.addRow([
      $translation("bulksms.statistic.fields.total.title"),
      ...totalValues
    ]);

    const currentRow = worksheet.actualRowCount;

    worksheet.getCell(`A${currentRow}`).font = { bold: true };
    worksheet.fillFormula(
      `B${currentRow}:E${currentRow}`,
      `SUM(B2:B${currentRow - 1})`,
      totalValues
    );

    // download file
    try {
      const filename = `statistics-${date}`;
      await Vue.$utils.saveFile(filename, workbook);
    } catch (error) {
      // empty
    }
  };


  if (!Vue.$bulksms) {
    Vue.$bulksms = Bulksms;
    Object.defineProperties(Vue.prototype, {
      $bulksms: {
        get() {
          return Bulksms;
        },
      },
    });
  }
}

VueJs.use(Bulksms);