<template>
  <div
    v-if="bulksmsUser.accountType === 'PrePaid' && $auth.check('ROLE_CLIENT')"
  >
    <v-tooltip>
      <template v-slot:activator="tooltip" bottom>
        <v-btn
          v-on="tooltip.on"
          v-bind:attrs="tooltip.attrs"
          @click.stop="refreshBulkSmsBalance()"
          outlined
        >
          <v-icon>mdi-</v-icon
          >{{
            $utils.pad(
              $utils.numberFormat(
                bulksmsUser.balance,
                $utils.setLocaleBCP47($i18n.locale),
                0,
                "decimal"
              ),
              2
            )
          }}
          SMS
        </v-btn>
      </template>
      <span>{{ $t("btn.refresh") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({}),
  created() {
    this.refreshBulkSmsBalance();
  },
  methods: {
    async refreshBulkSmsBalance() {
      if (!this.$auth.check(["ROLE_CLIENT"])) return;
      try {
        await this.getBulksmsInfo();
      } catch (error) {
        // empty
      }
    },
    ...mapActions({ getBulksmsInfo: "bulksms/getUser" }),
  },
  computed: {
    ...mapGetters({ bulksmsUser: "bulksms/user" }),
  },
};
</script>
