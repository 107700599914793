import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import i18n from "./../i18n";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: "fr"
  },
  theme: {
    themes: {
      light: {
        bg: "#f6f7f9",
        primary: "#0E245B", // "#233F83"
        secondary: "#F36B25",
        danger: "#B71C1C",
        success: "#00BFA5",
        warning: "#FF6F00",
        third: "#eff2f7",
        fourth: "#e8eff5",
        om: "#F76300",
        momo: "#F4C317",
        eum: "#1C5DA2",
        paypal: "#071E85"
      }
    }
  }
});
