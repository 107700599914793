export default {
  namespaced: true,
  state: {
    roles: [
      { value: 'ROLE_ADMIN', text: 'user.role.ROLE_ADMIN' },
      { value: 'ROLE_COMMERCIAL', text: 'user.role.ROLE_COMMERCIAL' },
      { value: 'ROLE_COMPTA', text: 'user.role.ROLE_COMPTA' },
      { value: 'ROLE_CLIENT', text: 'user.role.ROLE_CLIENT' },
    ]
  },
  getters: {
    roles: (state) => {
      return state.roles
    }
  }
}