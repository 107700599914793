import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "./../views/layout.vue";
import i18n, { loadLanguageAsync, allowedLanguages } from "./../i18n";

import PageNotFound from "./../views/errors/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale(fr|en)?",
    component: Layout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./../views/dashboard.vue"),
      },
      {
        path: "admin/packs",
        name: "pack-admin",
        component: () => import("./../views/packs/admin/index.vue"),
        meta: {
          auth: {
            roles: ["ROLE_ADMIN"],
          },
        },
      },
      {
        path: "packs",
        name: "pack",
        component: () => import("./../views/packs/index.vue"),
      },
      {
        path: "packs/:slug-:id(\\d+)",
        name: "pack-show",
        component: () => import("./../views/packs/show.vue"),
      },
      {
        path: "payments",
        name: "payment",
        component: () => import("./../views/payments/index.vue"),
      },
      {
        path: "account",
        component: () => import("./../views/account/index.vue"),
        children: [
          {
            path: "",
            name: "account",
            component: () => import("./../views/account/profile.vue"),
          },
          {
            path: "api",
            name: "api",
            component: () => import("./../views/account/api.vue"),
          },
          {
            path: "security",
            name: "security",
            component: () => import("./../views/account/security.vue"),
          },
        ],
      },
      {
        path: "users",
        name: "user",
        component: () => import("./../views/users/index.vue"),
        meta: {
          auth: {
            roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL", "ROLE_COMPTA"],
          },
        },
      },
      {
        path: "bulksms",
        component: () => import("./../views/bulksms/index.vue"),
        meta: {
          roles: ["ROLE_CLIENT"],
        },
        children: [
          {
            path: "campaigns",
            component: () => import("./../views/bulksms/campaigns/index.vue"),
            children: [
              {
                path: "",
                name: "bulksms",
                component: () =>
                  import("./../views/bulksms/campaigns/simple.vue"),
              },
              {
                path: "simple/list",
                name: "bulksms-campaign-simple-list",
                component: () =>
                  import("./../views/bulksms/campaigns/simple-list.vue"),
              },
              {
                path: "pro",
                name: "bulksms-campaign-file",
                component: () =>
                  import("./../views/bulksms/campaigns/file.vue"),
              },
              {
                path: "pro/list",
                name: "bulksms-campaign-file-list",
                component: () =>
                  import("./../views/bulksms/campaigns/file-list.vue"),
              },
            ],
          },
          {
            path: "sms",
            component: () => import("./../views/bulksms/sms/index.vue"),
            children: [
              {
                path: "",
                name: "bulksms-sms",
                component: () => import("./../views/bulksms/sms/sent.vue"),
              },
            ],
          },
          {
            path: "archives",
            name: "bulksms-archive",
            component: () => import("./../views/bulksms/archives/index.vue"),
          },
          {
            path: "provisions",
            name: "bulksms-provision",
            component: () => import("./../views/bulksms/provisions/index.vue"),
          },
          {
            path: "api",
            name: "bulksms-api",
            component: () => import("./../views/bulksms/api/index.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:locale(fr|en)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "register",
        component: () => import("./../views/security/register.vue"),
        name: "register",
      },
      {
        path: "register-verify",
        component: () => import("./../views/security/register-verify.vue"),
        name: "register-verify",
        meta: {
          auth: true,
        },
      },
      {
        path: "login",
        component: () => import("./../views/security/login.vue"),
        name: "login",
      },
      {
        path: "forgot-password",
        component: () => import("./../views/security/forgot-password.vue"),
        name: "forgot-password",
      },
      {
        path: "forgot-password/confirm",
        component: () =>
          import("./../views/security/forgot-password-confirm.vue"),
        name: "forgot-password-confirm",
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("./../views/security/logout.vue"),
      },
    ],
  },
  {
    path: "/404",
    component: PageNotFound,
    name: "404",
  },
  {
    path: "/403",
    component: () => import("./../views/errors/403.vue"),
    name: "403",
  },
  {
    path: "*",
    name: "no-route",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// manage locale
router.beforeEach(async (to, from, next) => {
  let locale = "",
    localeUrlSegment = to.path.split("/"),
    redirect = false;

  // Get locale from path
  localeUrlSegment.shift();
  locale = localeUrlSegment[0];

  // Locale fallback
  if (!allowedLanguages.includes(locale)) {
    locale = i18n.locale;
    redirect = true;
  }

  // Set locale
  await loadLanguageAsync(locale);
  to.params.locale = locale;

  // Move on the next hook (render component view)
  if (redirect && (!to.name || !["no-route", "404", "403"].includes(to.name))) {
    next(to);
  } else {
    next();
  }
});

// manage redirection to referer
router.beforeEach((to, from, next) => {
  const securityRoutesName = [
    "login",
    "register",
    "register-verify",
    "forgot-password",
    "forgot-password-confirm",
  ];

  if (!securityRoutesName.includes(to.name)) {
    if (to.name) {
      if (!Vue.prototype.$auth.check()) {
        sessionStorage.setItem("_redirect_path", to.path);
      } else if (
        Vue.prototype.$auth.user() &&
        !Vue.prototype.$auth.user().active
      ) {
        sessionStorage.setItem("_redirect_path", to.path);
      }
    }
  }

  next();
});

Vue.router = router;
export default router;
