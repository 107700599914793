import VueJs from "vue";

const Utils = {};

const providers = {
  orange: /^(237)?6((9[0-9]{7})|(5[5-9][0-9]{6})|(8[6-9][0-9]{6}))$/,
  mtn: /^(237)?6((7[0-9]{7})|(5[0-4][0-9]{6})|(8[0-4][0-9]{6}))$/,
  camtel: /^(237)?(242|243)[0-9]{6}$/,
  nexttel: /^(237)?6((6[0-9]{7})|(85[0-9]{6}))$/,
  all: /^(237)?((6(?:[5-9][0-9]{7}))|((242|243|620)[0-9]{6}))$/,
  all_prefixed: /^(237)((6(?:[5-9][0-9]{7}))|((242|243|620)[0-9]{6}))$/,
};

Utils.install = function(Vue) {
  Utils.setLocaleBCP47 = function(lang) {
    let locale;
    switch (lang) {
      case "fr":
        locale = "fr-FR";
        break;
      default:
        locale = "en-US";
    }
    return locale;
  };

  Utils.censorWord = function(str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  };

  Utils.censorEmail = function(email) {
    if (!email) return;
    const parts = email.split("@");
    if (parts.length === 1) {
      return Utils.censorWord(parts[0]);
    }
    return Utils.censorWord(parts[0]) + "@" + Utils.censorWord(parts[1]);
  };

  Utils.isValidEmail = function(value) {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );
  };

  Utils.regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/;

  Utils.capitalize = function(str) {
    if (typeof str !== "string" || !str) return "";
    const s = str.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  Utils.fullName = function(firstName, lastName) {
    const name1 = (firstName || "").replace(/\s\s+/g, " ");
    const name2 = (lastName || "").replace(/\s\s+/g, " ");
    return Utils.capitalize(name1) + " " + name2.toUpperCase();
  };

  Utils.validateQuantityPack = function(value) {
    if (!/^([0-9]+)-([0-9]+)$/.test(value)) return false;
    const quantities = value.split("-");
    if (quantities.length < 2) return false;
    if (parseInt(quantities[0]) > parseInt(quantities[1])) return false;
    return true;
  };

  Utils.validateQuantityPayment = function(value, range) {
    const quantities = range.split("-");
    return (
      parseInt(value) >= parseInt(quantities[0]) &&
      parseInt(value) <= parseInt(quantities[1])
    );
  };

  Utils.validateSender = function(value) {
    return /^(([^\s\W_][\s\w\W]{2,11})|([0-9]{1,15}))$/.test(value);
  };

  Utils.swatchStyle = function(menu, color) {
    return {
      backgroundColor: color,
      cursor: "pointer",
      height: "25px",
      width: "25px",
      borderRadius: menu ? "50%" : "4px",
      transition: "border-radius 200ms ease-in-out",
    };
  };

  Utils.numberFormat = function(
    value,
    lang,
    minimumFractionDigits = 0,
    style = "currency",
    currency = "XAF"
  ) {
    var formatter = new Intl.NumberFormat(lang, {
      style: style,
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
    });

    return formatter.format(value);
  };

  Utils.validateAccountNumber = function(account, accountType) {
    let isValid = false;
    switch (accountType) {
      case "OM":
        isValid = providers.orange.test(account);
        break;
      case "MOMO":
        isValid = providers.mtn.test(account);
        break;
      case "EUM":
        isValid = providers.all.test(account);
        break;
    }
    return isValid;
  };

  Utils.countSms = function(value) {
    if (!value) return 0;
    return Math.ceil(value.length / 160);
  };

  Utils.pad = function pad(num, size) {
    if (parseInt(num) === 0) return 0;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  Utils.linesToArrayNumbers = function(text, onlyLocalPhone = true) {
    let result = {
      invalid: [],
      valid: [],
    };
    const valid = new Set();

    if (!text) return result;

    const lines = text.split("\n");
    for (let i = 0; i < lines.length; i++) {
      if (
        lines[i].trim() != "" &&
        lines[i].trim() != null &&
        /^\d+$/.test(lines[i])
      ) {
        if (onlyLocalPhone) {
          if (providers.all_prefixed.test(lines[i])) {
            valid.add(lines[i]);
          } else {
            result.invalid.push({ text: lines[i], line: i });
          }
        } else {
          valid.add(lines[i]);
        }
      } else {
        result.invalid.push({ text: lines[i], line: i });
      }
    }
    result.valid = Array.from(valid);
    return result;
  };

  Utils.formatSize = function(size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
    } else if (size > 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
    } else if (size > 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + " MB";
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + " KB";
    }
    return (size || 0).toString() + " B";
  };

  Utils.isSmsSource = function(source) {
    return [
      "api_public",
      "file__input_bulk",
      "file__csv_bulk",
      "push_web",
    ].includes(source);
  };

  Utils.truncate = function(fullStr, len, separator) {
    let strLen;

    if (!Array.isArray(len)) len = [len];
    if (/\s+/.test(fullStr)) {
      strLen = len[0];
    } else {
      strLen = len[1] ? len[1] : len[0];
    }

    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator;
  };

  Utils.downloadSampleFile = async function() {
    const options = {
      formatterOptions: {
        delimiter: ";",
      },
    };
    const workbook = new Vue.$exceljs.Workbook();
    const worksheet = workbook.addWorksheet("Bulksms csv template");
    worksheet.addRow([
      parseFloat("237679293431"),
      "mysender",
      "hello bulksms user1",
    ]);
    worksheet.addRow([
      parseFloat("237679293432"),
      "mysender2",
      "hello bulksms user2",
    ]);

    // download file
    try {
      const filename = "bulksms-csv-template.csv";
      await Utils.saveFile(filename, workbook, "csv", "text/csv", options);
    } catch (error) {
      // empty
    }
  };

  Utils.saveFile = async function(
    filename,
    workbook,
    workbookType = "xlsx",
    type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    options = { base64: true }
  ) {
    const xls64 = await workbook[workbookType].writeBuffer(options);
    Vue.$filesaver(
      new Blob([xls64], {
        type: type,
      }),
      filename
    );
  };

  Utils.getUrlFile = function(file) {
    const baseUrl = process.env.VUE_APP_BASE_API;
    return `${baseUrl}/${file}`;
  };

  Utils.daterangeAlign = function(dates) {
    if (!Array.isArray(dates)) return null;
    if (dates.length !== 2) return null;
    if (!dates.every((val) => val)) return null;

    let start = dates[0];
    let end = dates[1];
    if (Vue.moment(start, "YYYY-MM-DD").isAfter(end, "days")) {
      start = dates[1];
      end = dates[0];
    }
    return [start, end];
  };

  Utils.downloadUri = function(uri, name) {
    if (!name) {
      name = uri.split("/").slice(-1)[0];
    }
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  Utils.parseDaterange = function(range, format = "YYYY-MM-DD") {
    if (typeof range != "object") return null;

    if (!range.length) return null;

    if (range.length === 1) {
      return [range[0], range[0]];
    }

    const start = Vue.prototype.$moment(range[0], format);
    const end = Vue.prototype.$moment(range[1], format);

    if (start.isBefore(end)) {
      return [start.format(format), end.format(format)];
    } else {
      return [end.format(format), start.format(format)];
    }
  };

  if (!Vue.$utils) {
    Vue.$utils = Utils;
    Object.defineProperties(Vue.prototype, {
      $utils: {
        get() {
          return Utils;
        },
      },
    });
  }
};

VueJs.use(Utils);
