import Vue from "vue";
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";

const RequestBuilder = {};

RequestBuilder.install = function(Vue) {
  RequestBuilder.create = function() {
    return RequestQueryBuilder.create();
  };
  RequestBuilder.operator = function() {
    return CondOperator;
  };

  if (!Vue.$requestBuilder) {
    Vue.$requestBuilder = RequestBuilder;
    Object.defineProperties(Vue.prototype, {
      $requestBuilder: {
        get() {
          return RequestBuilder;
        }
      }
    });
  }
};

Vue.use(RequestBuilder);