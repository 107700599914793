<template>
  <div>
    <v-snackbar
      :absolute="notification.absolute"
      :multi-line="notification.multiLine"
      :color="notification.color"
      :bottom="notification.bottom"
      :left="notification.left"
      :right="notification.right"
      :top="notification.top"
      :centered="notification.centered"
      v-model="notification.status"
      :timeout="-1"
      v-if="notification.type === 'small'"
    >
      {{ notification.message }}
      <template v-slot:action>
        <v-icon @click="closeNotification()" right class="white--text"
          >mdi-close</v-icon
        >
      </template>
    </v-snackbar>
    <v-snackbar
      light
      timeout="-1"
      class="conversation-notification"
      v-model="notification.status"
      :bottom="notification.bottom"
      :left="notification.left"
      :right="notification.right"
      :top="notification.top"
      v-else-if="notification.type === 'large'"
    >
      <template v-slot:default>
        <v-card flat max-width="300" class="px-0 py-0">
          <v-list three-line class="px-0 py-0">
            <v-list-item class="px-0">
              <v-list-item-avatar class="social-network mr-3" v-if="notification.avatar">
                <v-img :src="notification.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="align-self-start">
                <v-list-item-title class="mb-2">
                  <span class="font-weight-bold">
                    {{ notification.title }}
                  </span>
                </v-list-item-title>
                <div
                    v-html="notification.message"
                    class="text-body-2"
                  >
                  </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </template>
      <template v-slot:action>
        <v-btn icon text @click="closeNotification()"><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({}),
  methods: {
    ...mapActions({
      closeNotification: "notification/close",
    }),
  },
  computed: {
    ...mapGetters({ notification: "notification/notification" }),
  },
};
</script>