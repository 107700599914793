import Vue from 'vue';
const exceljs = require("exceljs");
const ExcelJs  = {}

ExcelJs.install = function(Vue) {
  if (!Vue.$exceljs) {
    Vue.$exceljs = exceljs;
    Object.defineProperties(Vue.prototype, {
      $exceljs: {
        get() {
          return exceljs;
        }
      }
    });
  }
};

Vue.use(ExcelJs);