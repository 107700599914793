import { BULKSMS_USER } from "./../mutations-types";

export default {
  namespaced: true,
  state: {
    user: {},
    trackings: [
      { text: "bulksms.campaign.fields.dlr.PENDING", value: "PENDING" },
      { text: "bulksms.campaign.fields.dlr.DELIVERED", value: "DELIVERED" },
      { text: "bulksms.campaign.fields.dlr.NOT_DELIVERED", value: "NOT_DELIVERED" },
    ],
    operators: [
      { text: "bulksms.campaign.fields.operator.mtn", value: "mtn" },
      { text: "bulksms.campaign.fields.operator.ocm", value: "ocm" },
      { text: "bulksms.campaign.fields.operator.ctl", value: "ctl" },
      { text: "bulksms.campaign.fields.operator.nex", value: "nex" },
      { text: "bulksms.campaign.fields.operator.other", value: "other" }
    ],
    smsSources: [
      { text: "bulksms.campaign.fields.source.api_public", value: "api_public" },
      { text: "bulksms.campaign.fields.source.push_web", value: "push_web" },
      { text: "bulksms.campaign.fields.source.file__csv_bulk", value: "file__csv_bulk" }
    ],
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    trackings: (state) => {
      return state.trackings;
    },
    smsSources: (state) => {
      return state.smsSources;
    },
    operators: (state) => {
      return state.operators;
    },
    userIsDisabled: (state) => {
      return !state.user.enabled
    },
    creditIsSufficient: (state) => {
      if (state.user.accountType === "PrePaid" && state.user.balance < 1) {
        return false;
      }
      return true;
    },
  },
  mutations: {
    [BULKSMS_USER](state, data) {
      state.user = data;
    },
  },
  actions: {
    async getUser(store, params = { reload: true }) {
      const user = store.getters.user;
      if(Object.keys(user).length && !params.reload) return;
      const response = await store.dispatch(
        "request",
        { url: "/api/providers/bulksms/me" },
        { root: true }
      );
      const data = response.data;
      store.commit(BULKSMS_USER, data);
      return response;
    },
  },
};
