<template>
  <v-menu close-on-click offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        dark
        v-bind="{...btnOptions, ...attrs}"
        v-on="on"
      >
        <v-tooltip v-bind="tooltipOptions">
          <template v-slot:activator="props">
            <v-img
              v-on="props.on"
              v-bind="props.attrs"
              :src="flag"
              width="18"
              contain
            ></v-img>
          </template>
          <span>{{ $t("btn.change_lang") }}</span>
        </v-tooltip>
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item @click="changeLocale($i18n.locale === 'fr' ? 'en' : 'fr')">
        <v-list-item-title class="py-0">
          <span class="text-body-1 font-weight-bold">{{
            $t(`locales.${$i18n.locale === "fr" ? "en" : "fr"}`)
          }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import flagFr from "@/assets/img/flags/fr.svg";
import flagEn from "@/assets/img/flags/gb.svg";
export default {
  props: {
    btnOptions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    tooltipOptions: {
      type: Object,
      default: function() {
        return {
          top: true
        }
      }
    }
  },
  data: () => ({}),
  methods: {
    async changeLocale(lang) {
      const route = this.$route;
      const params = { ...route.params, locale: lang };
      this.$router.replace({ ...route, params: params });
    },
  },
  computed: {
    flag() {
      return this.$i18n.locale === "fr" ? flagFr : flagEn;
    },
  },
};
</script>