import Vue from 'vue';

const filesaver = require("file-saver");
const FileSaver = {}
FileSaver.install = function(Vue) {
  if (!Vue.$filesaver) {
    Vue.$filesaver = filesaver;
    Object.defineProperties(Vue.prototype, {
      $filesaver: {
        get() {
          return filesaver;
        }
      }
    });
  }
};

Vue.use(FileSaver)