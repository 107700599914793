export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_ITEM = "NOTIFICATION_ITEM";
export const SNACKBAR = "SNACKBAR";
export const SET_CURRENCY_RATE = "SET_CURRENCY_RATE";
export const SET_PROVIDERS_PAYMENT = "SET_PROVIDERS_PAYMENT";
export const SET_REGIONS = 'SET_REGIONS';

export const BULKSMS_USER = "BULKSMS_USER";

export const SET_IMPERSONATE = "SET_IMPERSONATE";
