import VueI18n from "vue-i18n";
import Vue from "vue";

import fr from "./../i18n/fr.json";

Vue.use(VueI18n);

// i18n
const i18n = new VueI18n({
  locale: "fr",
  messages: { fr },
  fallbackLocale: "fr"
});

const loadedLanguages = ["fr"];
export const allowedLanguages = (
  process.env.VUE_APP_ALLOWED_LANGUAGES || "fr,en"
).split(",");

function setI18nLanguage(lang) {
  i18n.locale = lang;
  Vue.axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  if(!allowedLanguages.includes(lang)) { return; }

  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  const messages = await import(`@/i18n/${lang}.json`);
  i18n.setLocaleMessage(lang, messages);
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}

i18n.getRouteLink = function(name, params = {}, query = {}) {
  return { name: name, params: {...params, locale: i18n.locale}, query: query }
}

export default i18n;
