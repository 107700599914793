<template>
  <v-app class="app bg-color">
    <v-app-bar app color="primary" clipped-left dark>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <template>
          <v-icon>mdi-text</v-icon>
        </template>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="pl-1 app-title">
        <v-img :src="require('@/assets/img/logo-white.png')" width="45"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <bulksms-balance-item class="mr-3"></bulksms-balance-item>
      <lang
        :btnOptions="{ small: true, class: 'mr-2' }"
        :tooltipOptions="{ bottom: true }"
      ></lang>
      <v-menu offset-y left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="40">
              <v-img
                v-if="$objectPath.has(user, 'profile.avatar.path')"
                :src="
                  $utils.getUrlFile(
                    $objectPath.get(user, 'profile.avatar.path.medium')
                  )
                "
                :alt="$objectPath.get(user, 'email')"
              ></v-img>
              <v-img
                v-else
                :src="require('@/assets/img/user.png')"
                :alt="$objectPath.get(user, 'email')"
              ></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-list-item-title>
          <v-list class="py-1">
            <v-list-item>
              <v-list-item-avatar size="40">
                <v-img
                  v-if="$objectPath.has(user, 'profile.avatar.path')"
                  :src="
                    $utils.getUrlFile(
                      $objectPath.get(user, 'profile.avatar.path.medium')
                    )
                  "
                  :alt="$objectPath.get(user, 'email')"
                />
                <img
                  v-else
                  :src="require('@/assets/img/user.png')"
                  :alt="$objectPath.get(user, 'email')"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold grey--text text--darken-4">{{
                    fullname
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $objectPath.get(user, "email") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="$i18n.getRouteLink('account')">
              <v-list-item-title>
                <span class="text-body-1">
                  {{ $t("menu.account.title") }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <template v-if="$auth.impersonating()">
              <v-list-item @click="unimpersonate()">
                <v-list-item-title>
                  <span class="text-body-1">{{
                    $t("menu.unimpersonate")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
            </template>
            <v-list-item :to="{ name: 'logout' }">
              <v-list-item-title>
                <span class="text-body-1">{{ $t("menu.logout") }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-item-title>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      class="navigation"
      :color="
        !$vuetify.breakpoint.mdAndDown ? 'rgba(255,255,255, 0)' : '#ffffff'
      "
      app
      clipped
      v-model="drawer"
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        :style="{ height: windowHeight + 'px' }"
      >
        <div>
          <v-list>
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-img
                  v-if="$objectPath.has(user, 'profile.avatar.path')"
                  :src="
                    $utils.getUrlFile(
                      $objectPath.get(user, 'profile.avatar.path.medium')
                    )
                  "
                  :alt="$objectPath.get(user, 'email')"
                />
                <v-img v-else :src="require('@/assets/img/user.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold grey--text text--darken-4">{{
                    fullname
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list flat dense nav subheader class="pt-4">
            <v-list-item
              link
              :to="$i18n.getRouteLink('dashboard')"
              class="mb-2"
              v-if="$auth.check(['ROLE_CLIENT'])"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute('dashboard') }"
                  >mdi-view-dashboard-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute('dashboard') }"
                  >{{ $t("menu.dashboard") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <template v-if="$auth.check(['ROLE_ADMIN'])">
              <v-list-item
                active-class="secondary--text"
                link
                :to="$i18n.getRouteLink('pack-admin')"
                class="mb-2"
              >
                <v-list-item-icon>
                  <v-icon>mdi-archive-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ $t("menu.pack.title") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-if="$auth.check(['ROLE_CLIENT'])">
              <v-list-item
                class="mb-2"
                active-class="secondary--text"
                link
                :to="$i18n.getRouteLink('pack')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-package-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ $t("menu.pack.buy") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              active-class="secondary--text"
              link
              :to="$i18n.getRouteLink('payment')"
            >
              <v-list-item-icon>
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ $t("menu.payment") }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="!$auth.check(['ROLE_CLIENT'])">
              <v-list-item
                active-class="secondary--text"
                link
                :to="$i18n.getRouteLink('user')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ $t("menu.user") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              active-class="secondary--text"
              link
              :to="$i18n.getRouteLink('account')"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ $t("menu.account.title") }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="$auth.check('ROLE_CLIENT')">
              <v-subheader>
                <span class="text-body-2 grey--text">
                  {{ $t("menu.subheader.application") }}
                </span>
              </v-subheader>
              <v-list-group
                class="mb-1"
                prepend-icon="mdi-email-outline"
                no-action
                :value="isRoute(routes.bulksms.all)"
                :class="{
                  'secondary--text': isRoute(routes.bulksms.all),
                }"
                :active-class="
                  isRoute(routes.bulksms.all) ? 'secondary--text' : ''
                "
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>BulkSMS</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  link
                  :to="$i18n.getRouteLink('bulksms')"
                  :active-class="
                    isRoute(routes.bulksms.campaign) ? 'secondary--text' : ''
                  "
                  :exact-active-class="
                    isRoute(routes.bulksms.campaign) ? 'secondary--text' : ''
                  "
                >
                  <v-list-item-title class="font-weight-bold">
                    {{ $t("menu.bulksms.campaign") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="$i18n.getRouteLink('bulksms-sms')"
                  :active-class="
                    isRoute(routes.bulksms.sms) ? 'secondary--text' : ''
                  "
                  :exact-active-class="
                    isRoute(routes.bulksms.sms) ? 'secondary--text' : ''
                  "
                >
                  <v-list-item-title class="font-weight-bold">
                    {{ $t("menu.bulksms.sent") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="$i18n.getRouteLink('bulksms-provision')"
                  :active-class="
                    isRoute(routes.bulksms.provision) ? 'secondary--text' : ''
                  "
                  :exact-active-class="
                    isRoute(routes.bulksms.provision) ? 'secondary--text' : ''
                  "
                >
                  <v-list-item-title class="font-weight-bold">
                    {{ $t("menu.bulksms.provision") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="$i18n.getRouteLink('bulksms-archive')"
                  :active-class="
                    isRoute(routes.bulksms.archive) ? 'secondary--text' : ''
                  "
                  :exact-active-class="
                    isRoute(routes.bulksms.archive) ? 'secondary--text' : ''
                  "
                >
                  <v-list-item-title class="font-weight-bold">
                    {{ $t("menu.bulksms.archive") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="$i18n.getRouteLink('bulksms-api')"
                  :active-class="
                    isRoute(routes.bulksms.api) ? 'secondary--text' : ''
                  "
                  :exact-active-class="
                    isRoute(routes.bulksms.api) ? 'secondary--text' : ''
                  "
                >
                  <v-list-item-title class="font-weight-bold">
                    {{ $t("menu.bulksms.api") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer color="rgba(255,255,255,0)" class="mt-5">
      <v-spacer></v-spacer>
      <div class="font-weight-bold grey--text text--darken-1 text-body-2">
        &copy; LMTGROUP 2021
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Lang from "./../components/commons/lang";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import BulksmsBalanceItem from "./../components/bulksms/balance-item";

const routes = {
  bulksms: {
    campaign: [
      "bulksms",
      "bulksms-campaign-file",
      "bulksms-campaign-simple-list",
      "bulksms-campaign-file-list",
    ],
    sms: ["bulksms-sms"],
    archive: ["bulksms-archive"],
    provision: ["bulksms-provision"],
    api: ["bulksms-api"],
  },
};

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    const user = Vue.prototype.$auth.user();
    if (!user?.active) {
      next({ name: "register-verify" });
    } else {
      next();
    }
  },
  mounted: function() {
    let self = this;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight - 70;

    this.$nextTick(function() {
      window.addEventListener("resize", function() {
        self.windowHeight = window.innerHeight - 70;
      });
      window.addEventListener("resize", function() {
        self.windowWidth = window.innerWidth;
      });
    });
  },
  data: () => ({
    drawer: true,
    loading: true,
    windowHeight: null,
    windowWidth: null,
    routes: {
      bulksms: {
        campaign: [...routes.bulksms.campaign],
        sms: [...routes.bulksms.sms],
        archive: [...routes.bulksms.archive],
        provision: [...routes.bulksms.provision],
        api: [...routes.bulksms.api],
        all: [
          ...routes.bulksms.campaign,
          ...routes.bulksms.sms,
          ...routes.bulksms.archive,
          ...routes.bulksms.provision,
          ...routes.bulksms.api,
        ],
      },
    },
  }),
  methods: {
    isRoute(routes) {
      return routes.includes(this.$route.name);
    },
    async unimpersonate() {
      try {
        const tokens = this.$auth.token();
        let tokensArray = tokens.split("||");
        await this.$auth.unimpersonate({
          data: {
            refresh_token: tokensArray[1],
          },
        });
      } catch (error) {
        // empty
      }
    },
    ...mapActions({
      request: "request",
    }),
  },
  computed: {
    fullname() {
      return this.$utils.fullName(
        this.user?.profile?.firstName,
        this.user?.profile?.lastName
      );
    },
    role() {
      return this.user?.role ? this.$t("user.role." + this.user?.role) : "";
    },
    user() {
      return this.$auth.user();
    },
    ...mapGetters({ impersonated: "impersonate" }),
  },
  components: {
    PerfectScrollbar,
    BulksmsBalanceItem,
    Lang,
  },
};
</script>
