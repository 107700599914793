import Vue from 'vue'
import App from './App.vue'
import i18n from "./i18n";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

/* import plugins */
import "./plugins/vueaxios";
import "./plugins/vueauth";
import "./plugins/vuelidate";
import "./plugins/vuelodash";
import "./plugins/vuemeta";
import "./plugins/vuemoment";
import "./plugins/vuerequestbuilder";
import "./plugins/vuemask";
import "./plugins/vueupload";
import "./plugins/vueobjectpath";
import "./plugins/vuefilesaver";
import "./plugins/vueexcel";
import "./plugins/vuegooglechart";
import "./plugins/vueclipboard";
import "./plugins/vuerecaptcha";
import "./plugins/vueawesomenumber";
import "./plugins/vueutils";
import "./plugins/vuebulksms";
import "./plugins/vueloadscript";

// import css vendors
import "flag-icon-css/css/flag-icon.min.css";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "cropperjs/dist/cropper.min.css"

Vue.config.productionTip = false

export const bus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
