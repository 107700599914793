import VueJs from 'vue';
import PhoneNumber from 'awesome-phonenumber';

const AwsPhoneNumber = {};

AwsPhoneNumber.install = function(Vue) {
  if (!Vue.$awesomenumber) { 
    Vue.$awesomenumber = PhoneNumber;
    Object.defineProperties(Vue.prototype, {
      $awesomenumber: {
        get() {
          return PhoneNumber;
        },
      },
    });
  }
}

VueJs.use(AwsPhoneNumber);