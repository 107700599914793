<template>
  <v-app id="app">
    <notification></notification>
    <template v-if="$auth.ready()">
      <router-view></router-view>
    </template>
    <template v-else>
      <div class="text-center mt-5">
        <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
      </div>
    </template>
  </v-app>
</template>

<style lang="scss">
@import "./sass/app.scss";
</style>

<script>
import Notification from './components/commons/notification.vue';
import Vue from "vue";

export default {
  name: "App",
  created() {
    // set moment locale
    Vue.moment().locale(this.$vuetify.lang.current);
    // set impersonate on store from local storage
    this.$store.dispatch('setImpersonate', localStorage.getItem('_impersonate'))
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("app.description"),
      titleTemplate: "%s - Portail LMTGROUP"
    };
  },
  components: {
    Notification
  }
};
</script>
