import VueJs from 'vue';
import objectPath from "object-path";

const ObjectPath = {};
ObjectPath.install = function(Vue) {
  if (!Vue.$objectPath) { 
    Vue.$objectPath = objectPath;
    Object.defineProperties(Vue.prototype, {
      $objectPath: {
        get() {
          return objectPath;
        },
      },
    });
  }
}

VueJs.use(ObjectPath);
