import Vue from "vue";
import auth from "@websanova/vue-auth/src/v2.js";
import authBearer from "./authbearer";
import httpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import routerVueRouter from "./authrouter";

const tokenName =
  process.env.VUE_APP_TOKEN_NAME || "_security_lmtgroup_portail";
const tokenImpersonateName =
  process.env.VUE_APP_TOKEN_IMP_NAME || "_security_lmtgroup_portail_imp";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: authBearer,
    http: httpAxios,
    router: routerVueRouter,
  },
  options: {
    rolesKey: "role",
    stores: ["cookie"],
    cookie: {
      Secure: process.env.VUE_APP_COOKIE_SECURE == "1" ? true : false,
      Expires: parseInt(process.env.VUE_APP_COOKIE_EXPIRES || "86400000"),
      SameSite: "Strict",
    },
    tokenDefaultKey: tokenName,
    tokenImpersonateKey: tokenImpersonateName,
    authRedirect: { name: "login" },
    fetchData: {
      url: "/api/auth/me",
      method: "GET",
      enabled: true,
    },
    loginData: {
      url: "/api/auth/login",
      method: "POST",
      redirect: "/",
      fetchUser: true,
    },
    refreshData: {
      url: "/api/auth/refresh-token",
      method: "POST",
      enabled: true,
      interval: process.env.VUE_APP_RERESH_TOKEN_INTERVAL
        ? parseFloat(process.env.VUE_APP_RERESH_TOKEN_INTERVAL)
        : 15,
    },
    logoutData: {
      url: "/api/auth/logout",
      method: "POST",
      redirect: "/login",
      makeRequest: true,
      transformRequest: function(data) {
        data = JSON.stringify({
          refresh_token: Vue.auth.token(tokenName).split("||")[1],
        });
        return data;
      },
    },
    impersonateData: {
      url: "/api/auth/impersonate",
      method: "POST",
      redirect: "/payments",
      fetchUser: true,
    },
    unimpersonateData: {
      url: "/api/auth/unimpersonate",
      method: "POST",
      redirect: "/payments",
      fetchUser: true,
      makeRequest: true,
    },
    parseUserData: (data) => {
      return data;
    },
  },
});
