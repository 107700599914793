import {
  NOTIFICATION,
  NOTIFICATION_ITEM,
  SNACKBAR
} from "./../mutations-types";

const defaultOptions = {
  status: false,
  timeout: 6000,
  multiLine: false,
  left: false,
  bottom: false,
  right: true,
  top: true,
  vertical: false,
  centered: false,
  color: "rgba(0, 0, 0, 1)",
  absolute: false,
  message: "",
  transition: true,
  windows: false,
  title: null,
  icon: null,
  badge: null,
  type: 'small',
  avatar: null,
};

export default {
  namespaced: true,
  state: {
    notification: defaultOptions,
    snackbar: null
  },
  getters: {
    notification: state => {
      return state.notification;
    },
    snackbar: state => {
      return state.snackbar;
    }
  },
  mutations: {
    [SNACKBAR](state, snackbar) {
      state.snackbar = snackbar;
    },
    [NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [NOTIFICATION_ITEM](state, data) {
      state.notification[data.item] = data.value;
    }
  },
  actions: {
    notify: (store, options) => {
      // clear old snackbar
      clearTimeout(store.getters.snackbar);

      // merge default notifications options with current options and set notification
      let params = Object.assign({}, defaultOptions, { status: true }, options);

      store.commit(NOTIFICATION, params);
      // set snackbar
      let snackbar = window.setTimeout(function() {
        store.dispatch("close");
      }, params.timeout);
      store.commit(SNACKBAR, snackbar);
    },
    close: store => {
      store.commit(NOTIFICATION_ITEM, { item: "status", value: false });
    }
  }
};
